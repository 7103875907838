<template>
  <div class="card card-custom">
    <div class="card-header">
      <h3 class="card-title font-weight-bolder">{{ $t('REISEN.COMMON.REISETERMINE') }}</h3>
      <div class="card-toolbar">
        <MultipleSortOrder
          :sortOptions="fields.filter(field => field.sortable)"
          v-model="sortExpressions"
          @update="onUpdatedSortExpressions"
        />
      </div>
    </div>
    <div class="card-body d-flex flex-column justify-content-between" :style="heightComponentInlineStyle">
      <b-table
        :items="itemsTransformed"
        :fields="fields"
        :sticky-header="updatedTableHeight + 'px'"
        :busy.sync="isLoading"
        :tbody-tr-class="rowClass"
        key="teamdashboard-garantiert-table"
        id="teamdashboard-garantiert-table"
        ref="teamdashboard-garantiert-table"
        responsive="sm"
        primary-key="id"
        no-local-sorting
        no-sort-reset
        sort-icon-left
        :empty-filtered-text="$t('COMMON.EMPTYFILTEREDTEXT', { name: 'Reisetermine' })"
        :empty-text="$t('COMMON.EMPTYFILTEREDTEXT', { name: 'Reisetermine' })"
        show-empty
        thead-class="text-nowrap"
        @sort-changed="onSortChanged"
        @head-clicked="onHeadClicked"
      >
        <template #head()="data">
          <div class="d-flex">
            {{ data.label }}
            <div class="d-flex text-xs font-weight-lighter flex-nowrap">
              <span v-if="getSortNumber(data.column)" class="ml-2">
                <b-badge v-b-tooltip.hover title="Priorität der Sortierung">
                  {{ getSortNumber(data.column) }}
                </b-badge>
              </span>
            </div>
          </div>
        </template>

        <template #cell(pax)="row">
          <PaxWidget
            class="mb-2"
            :id="row.item.id"
            :paxObject="row.item.pax"
            :minPax="row.item.metadata && row.item.metadata.minPax"
            :maxPax="row.item.metadata && row.item.metadata.maxPax"
            :kalkPax="row.item.metadata && row.item.metadata.kalkPax"
            :erwartetePax="row.item.metadata && row.item.metadata.erwartetePax"
            :durchfuehrbareMinPax="row.item.metadata && row.item.metadata.durchfuehrbareMinPax"
          ></PaxWidget>
        </template>

        <template #cell(agentur)="row">
          {{ row.item.agentur.id }}
        </template>

        <template #cell(kommentar)="row">
          <TableCommentCell
            :comment="row.item.dashboardReisetermin.kommentar"
            :id="row.item.id"
            :saveCommentFunction="saveCommentFunction"
          ></TableCommentCell>
        </template>

        <template #cell(actions)="row">
          <div class="d-flex">
            <ActionButton
              :link="{ name: 'reisetermine-view', params: { reiseterminId: row.item.id } }"
              title="Reisetermin Detailansicht"
              iconClass="fas fa-info"
            />
            <ActionButton
              @click="toggleErledigt(row.item)"
              :iconClass="
                row.item && row.item.dashboardReisetermin && row.item.dashboardReisetermin.istErledigt
                  ? 'fas fa-times'
                  : 'fas fa-check'
              "
              extraClass="text-danger"
              :title="
                row.item && row.item.dashboardReisetermin && row.item.dashboardReisetermin.istErledigt
                  ? 'Erledigen rückgängig machen'
                  : 'Erledigen'
              "
            />
            <ActionButton
              @click="toggleVisibility(row.item)"
              :iconClass="
                row.item && row.item.dashboardReisetermin && row.item.dashboardReisetermin.istAusgeblendet
                  ? 'far fa-eye'
                  : 'far fa-eye-slash'
              "
              extraClass="text-danger"
              :title="
                row.item && row.item.dashboardReisetermin && row.item.dashboardReisetermin.istAusgeblendet
                  ? 'Einblenden'
                  : 'Ausblenden'
              "
            />
            <ActionButton
              @click="togglePending(row.item)"
              :iconClass="
                row.item && row.item.dashboardReisetermin && row.item.dashboardReisetermin.istPending
                  ? 'fas fa-history'
                  : 'far fa-clock'
              "
              extraClass="text-danger"
              :title="
                row.item && row.item.dashboardReisetermin && row.item.dashboardReisetermin.istPending
                  ? 'Not pending'
                  : 'Pending'
              "
            />

            <ActionButton
              @click="row.toggleDetails"
              :iconClass="row.detailsShowing ? 'ki ki-arrow-up' : 'ki ki-arrow-down'"
              title="Ausklappen"
            />
          </div>
        </template>

        <template slot="row-details" slot-scope="row">
          <GarantiertTableDetails :reisetermin="row.item" />
        </template>
      </b-table>
      <SKRPagination
        v-model="currentPage"
        :isLoading="isLoading"
        :totalRows="allItemsCount"
        :currentRows="items.length || 0"
        ariaControls="teamdashboard-garantiert-table"
        @pageChange="onPageChange"
      ></SKRPagination>
    </div>
  </div>
</template>

<script>
import SKRPagination from '@/components/common/skr-pagination.vue';
import configPirority from '@/core/produkte/reisen/priority.config.json';
import ActionButton from '@/components/common/action-button.vue';
import MultipleSortOrder from '@/components/common/multiple-sort-order.vue';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import TableCommentCell from '@/components/kunden/table-comment-cell.vue';

import apiService from '@/core/common/services/api.service';

import GarantiertTableDetails from '@/components/produkte/reisetermine/team-dashboard-tabs-garantiert-table-details.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'TeamDashboardGarantiertTable',
  components: {
    SKRPagination,
    PaxWidget,
    GarantiertTableDetails,
    ActionButton,
    TableCommentCell,
    MultipleSortOrder,
  },
  props: {
    isLoading: { type: Boolean },
    items: { type: Array },
    allItemsCount: { type: Number },
    tableHeight: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      columnElementsByKey: {},
      sortExpressions: [
        { key: 'abreisedatum', desc: false },
        { key: 'reise.prioritaet', desc: false },
      ],
      fields: [
        {
          label: this.$t('COMMON.PRIORITAET'),
          key: 'reise.prioritaet',
          sortable: true,
          formatter: value => {
            return configPirority[value]?.value;
          },
          class: 'position-sticky sortable',
        },
        {
          label: this.$t('REISEN.COMMON.REISEKUERZEL'),
          sortable: true,
          key: 'reise.reisekuerzel',
          class: 'position-sticky sortable',
        },
        {
          label: this.$t('COLUMNNAMES.Abreisedatum'),
          sortable: true,
          key: 'abreisedatum',
          class: 'position-sticky sortable',
          formatter: value => {
            return this.$options.filters.date(value);
          },
        },
        {
          label: this.$t('COLUMNNAMES.Startdatum'),
          sortable: true,
          key: 'startdatum',
          class: 'position-sticky sortable',
          formatter: value => {
            return this.$options.filters.date(value);
          },
        },
        {
          key: 'pax',
        },
        {
          key: 'kommentar',
          label: this.$t('REISEN.COMMON.COMMENT'),
          tdClass: 'fixed-height-td',
        },
        {
          key: 'dashboardReisetermin.bearbeiter',
          label: 'Bearbeiter',
          formatter: value => this.$store.getters.getUsernameById(value),
        },
        {
          label: this.$t('REISEN.COMMON.ACTIONS'),
          key: 'actions',
        },
      ],
    };
  },
  watch: {
    async isLoading(v) {
      if (!v) {
        await this.$nextTick();
        this.getColumnElementsByKey();
        this.refreshAriaSort(this.sortExpressions);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUserId']),

    itemsTransformed() {
      return this.items.map(item => {
        return {
          ...item.reisetermin,
          dashboardReisetermin: {
            ...item.dashboardReisetermin,
          },
        };
      });
    },
    heightComponentInlineStyle() {
      return 'max-height:' + this.tableHeight + 15 + 'px';
    },
    updatedTableHeight() {
      const cardToolbarHeight = 120;
      return this.tableHeight - cardToolbarHeight;
    },
  },
  methods: {
    onUpdatedSortExpressions(sortExpressions) {
      this.refreshAriaSort(sortExpressions);
      this.$emit('sortChange', this.sortExpressions);
    },
    getColumnElementsByKey() {
      const allTHElements = document.querySelectorAll(
        '#teamdashboard-garantiert-table th.table-b-table-default'
      );
      this.columnElementsByKey = this.fields.reduce((acc, field, index) => {
        acc[field.key] = allTHElements[index];

        return acc;
      }, {});
    },
    toggleVisibility(item) {
      return apiService
        .put(`dashboards/fom/reisetermin/${item.id}`, {
          kommentar: item?.dashboardReisetermin?.kommentar || null,
          istAusgeblendet: !item?.dashboardReisetermin?.istAusgeblendet || false,
          istPending: item?.dashboardReisetermin?.istPending || false,
          istErledigt: item?.dashboardReisetermin?.istErledigt || false,
          bearbeiter: this.currentUserId,
        })
        .finally(() => {
          this.$emit('visbilityUpdated');
        });
    },
    togglePending(item) {
      return apiService
        .put(`dashboards/fom/reisetermin/${item.id}`, {
          kommentar: item?.dashboardReisetermin?.kommentar || null,
          istAusgeblendet: item?.dashboardReisetermin?.istAusgeblendet || false,
          istPending: !item?.dashboardReisetermin?.istPending || false,
          istErledigt: item?.dashboardReisetermin?.istErledigt || false,
          bearbeiter: this.currentUserId,
        })
        .finally(() => {
          this.$emit('visbilityUpdated');
        });
    },
    toggleErledigt(item) {
      return apiService
        .put(`dashboards/fom/reisetermin/${item.id}`, {
          kommentar: item?.dashboardReisetermin?.kommentar || null,
          istAusgeblendet: item?.dashboardReisetermin?.istAusgeblendet || false,
          istPending: item?.dashboardReisetermin?.istPending || false,
          istErledigt: !item?.dashboardReisetermin?.istErledigt || false,
          bearbeiter: this.currentUserId,
        })
        .finally(() => {
          this.$emit('visbilityUpdated');
        });
    },
    saveCommentFunction(id, comment) {
      return apiService
        .put(`dashboards/fom/reisetermin/${id}`, {
          kommentar: comment,
          istAusgeblendet:
            this.items.find(item => item.reisetermin.id === id)?.dashboardReisetermin?.istAusgeblendet ||
            false,
          istPending:
            this.items.find(item => item.reisetermin.id === id)?.dashboardReisetermin?.istPending || false,
          bearbeiter: this.currentUserId,
          istErledigt:
            this.items.find(item => item.reisetermin.id === id)?.dashboardReisetermin?.istErledigt || false,
        })
        .then(() => {
          let dashboardReiseterminOfItem = this.items.find(item => item.reisetermin.id === id);
          dashboardReiseterminOfItem['dashboardReisetermin'] = {
            ...dashboardReiseterminOfItem['dashboardReisetermin'],
            bearbeiter: this.currentUserId,
            kommentar: comment,
          };
          console.log('dasboard reisetermin', dashboardReiseterminOfItem);
        });
    },
    async onHeadClicked(key, field, $event, isFooter) {
      await this.$nextTick();
      this.refreshAriaSort(this.sortExpressions);
    },
    onSortChanged($event) {},
    getSortNumber(key) {
      return Array.isArray(this.sortExpressions) && this.sortExpressions.length > 1
        ? this.sortExpressions.findIndex(field => field.key === key) + 1
        : 0;
    },
    async refreshAriaSort(sortExpressions) {
      // delay to overwrite the default aria-sort attribute by b-table
      await this.$nextTick();
      document
        .querySelectorAll('#teamdashboard-garantiert-table th.table-b-table-default.sortable')
        .forEach(th => {
          if (th) {
            th.setAttribute('aria-sort', 'none');
          }
        });
      sortExpressions.forEach(expression => {
        if (this.columnElementsByKey[expression.key]) {
          this.columnElementsByKey[expression.key].setAttribute(
            'aria-sort',
            expression.desc ? 'descending' : 'ascending'
          );
        }
      });
    },
    rowClass(item, type) {
      return item && type === 'row' && item.changed ? 'bg-warning-o-50' : '';
    },
    onPageChange($event) {
      this.$emit('pageChange', $event);
      this.$refs['teamdashboard-garantiert-table'].$el.scrollTop = 0;
    },
  },
};
</script>
<style scoped lang="scss">
.table-dropdown > .btn {
  padding: 0;
}
.columnWidth10 {
  width: 10px;
}
.hidden {
  display: none;
}
.columnWidth100 {
  width: 100px;
}
.columnWidth150 {
  width: 150px;
}

.cw-80 {
  width: 150px;
}
</style>
